.emptyView {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 60vh;
  justify-content: center;
}

.illustration {
  align-items: center;
  aspect-ratio: 1;
  background-color: var(--color-surface);
  border-radius: 50%;
  color: var(--color-disabled);
  display: flex;
  justify-content: center;
  padding: 2rem;
  width: 8rem;
}

.illustration svg {
  width: 100%;
}

.title {
  color: var(--color-disabled);
  font-size: 1.2rem;
  font-weight: var(--medium-weight);
  margin: 1rem 10% 0 10%;
  text-align: center;
}
