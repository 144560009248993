.appSuspense {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}

.container{
  position: absolute;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.229);
  z-index: 9999999;
}

.text {
  font-weight: var(--medium-weight);
  font-size: 1.1rem;
  color: var(--color-on-primary);
  margin-top: 1rem;
}