.brandLogo {
  padding: 0.5rem;
  margin-bottom: 2rem;
  margin-left: 0.20rem;
  width: 4rem;
}

.logoutItem {
  color: var(--color-danger);
}

.logoutDialog {
  border-radius: var(--border-radius);
  height: fit-content;
  margin-top: 5rem;
  padding: 1rem;
}

.logoutDialogFooter button + button {
  margin-left: 0.5rem;
}

.lowerTabs {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tabs {
  background-color: var(--color-dark);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0.1rem;
  height: 100%;
}

.tab {
  align-items: center;
  border-radius: var(--border-radius);
  color: var(--color-on-dark);
  display: flex;
  flex-direction: column;
  font-weight: var(--medium-weight);
  opacity: 0.5;
  padding: 0.5rem 0.75rem;
  margin: 0.25rem;
  transition: background-color var(--animation-duration) ease-out;
}

.tab svg {
  width: 1.5rem;
  margin-bottom: 0.25rem;
}

.tab:hover {
  background-color: var(--color-primary-light-add);
  opacity: 1;
  text-decoration: none;
}

.tab.active {
  background-color: var(--color-primary);
  opacity: 1;
}

.noLabel {
  padding: 0.8rem 0.5rem;
  display: flex;
  align-items: center;
}

.noLabel svg {
  width: 1.8rem;
}

.profile {
  display: flex;
  align-items: center;
}

.profile svg {
  margin-right: 0.5rem;
  width: 1.3rem;
}