@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap');
@import url("https://cdn.syncfusion.com/ej2/20.3.56/bootstrap5.css");

:root {
  --animation-duration: 240ms;
  --border-radius: 0.75rem;

  --color-background: #fff;
  --color-danger: #ba314d;
  --color-dark: #192738;
  --color-on-dark: #fff;
  --color-on-primary: #fff;
  --color-primary: #5a45a7;
  --color-primary-light-add: #5a45a73c;
  --color-success: #418b4f;
  --color-surface: #f1f1f4;
  --color-surface-hover: #e4e4ed;
  --color-surface-light: #f8f8fa;
  --color-surface-2: #e3e3ed;

  --color-message-background-self: #cfd2e8;
  --color-message-mention-background: #b8bcd7;
  --color-message-background: #f0eef6;
  --color-shimmer: #d6d6df;

  --color-disabled: #999;
  --color-inactive: #746f89;
  --color-input-focused-border: #888;

  --medium-weight: 500;
  --font-family: system-ui, -apple-system, 'Helvetica Neue', 'Arial', sans-serif;
  --heading-size: 1.3rem;

  font-size: 15px;
}

html[data-theme="dark"] {
  --color-dark: #222;
  --color-inactive: #999;
  --color-message-background: #444;
  --color-message-background-self: #666;
  --color-message-mention-background: #848181;
  --color-primary: #5a45a7;
  --color-on-primary: #fff;
  --color-shimmer: #636363;
  --color-surface: #303030;
  --color-surface-2: #343434;
  --color-surface-hover: #3a3a3a;
  --color-surface-light: #333;
}

* {
  box-sizing: border-box;
}

*:hover > ::-webkit-scrollbar-thumb {
  background-color: #cbcbcb9c;
}

::-webkit-scrollbar {
  background-color: transparent;
  width: 4px;
}

::-webkit-scrollbar-thumb {
  background-color: transparent;
}

body {
  font-family: var(--font-family);
  margin: 0;
  font-weight: 400;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
  color: var(--color-primary);
}

a:hover {
  text-decoration: underline;
}

button {
  background-color: transparent;
  border: none;
  color: currentColor;
  cursor: pointer;
  font-family: var(--font-family);
  font-size: 1rem;
  font-weight: var(--medium-weight);
}

p {
  font-size: 1rem;
}

.fw-medium {
  font-weight: var(--medium-weight);
}

.context-menu{
  z-index: 999999 !important;
}