.main {
  display: flex;
  height: 100vh;
  max-height: 100vh;
  overflow-y: scroll;
}

.navWrapper {
  height: 100%;
}

.outlet {
  flex: 1;
}
